'use strict';

var $ = window.$;

$(document).ready(function () {



	$('.js-openMenu').on('click', function (e) {
		$(this).toggleClass('isOpen');
		$('#mobileMenu').toggleClass('isOpen');
	});

	$('.menuList_mobile').on('click', '.menuList__link', function (e) {
		$('.js-openMenu').removeClass('isOpen');
		$('#mobileMenu').removeClass('isOpen');
	});


	$('#interactiveSVG g').on('click', function (e) {
		var country = $(this).attr('id');
		$(this).siblings('g').removeClass('isActive');
		$(this).addClass('isActive');

		$('.aplication__country[data-name="' + country + '"]').addClass('isActive');
		$('.aplication__country[data-name="' + country + '"]').siblings().removeClass('isActive');
	});

	$('.js_infoAboutChange').on('click', function (e) {
		var index = $(this).data('index');
		var parentBlock = $(this).parents('.block__body_about');

		parentBlock.find('.slideNumber__value').removeClass('isActive');
		parentBlock.find('.slideNumber__value[data-index=' + index + ']').addClass('isActive');

		parentBlock.find('.infoCard').removeClass('isActive');
		parentBlock.find('.infoCard[data-index=' + index + ']').addClass('isActive');

		parentBlock.find('.numberList__value').removeClass('isActive');
		parentBlock.find('.numberList__value[data-index=' + index + ']').addClass('isActive');
	});

	setInterval(function () {
		if ($('.js_infoAboutChange:last-child').hasClass('isActive')) {
			$('.js_infoAboutChange:first-child').click();
		} else {
			$('.js_infoAboutChange.isActive').next().click();
		}
	}, 7000);

	$('.js_infoTeamChange').on('click', function (e) {
		var target = $(event.target);
		var parentBlock = $(this).parents('.block__body_team');
		var index = parentBlock.find('.teamInfo.isActive').data('index');
		var maxIndex = parentBlock.find('.teamInfo').length;

		console.log();

		if (target.hasClass('controleTab__button_left') && index > 0) {

			parentBlock.find('.teamInfo').removeClass('isActive');
			parentBlock.find('.teamInfo[data-index=' + (index - 1) + ']').addClass('isActive');

			parentBlock.find('.slidePhoto__img').removeClass('isActive');
			parentBlock.find('.slidePhoto__img[data-index=' + (index - 1) + ']').addClass('isActive');
		} else if (target.hasClass('controleTab__button_right') && index < maxIndex - 1) {

			parentBlock.find('.teamInfo').removeClass('isActive');
			parentBlock.find('.teamInfo[data-index=' + (index + 1) + ']').addClass('isActive');

			parentBlock.find('.slidePhoto__img').removeClass('isActive');
			parentBlock.find('.slidePhoto__img[data-index=' + (index + 1) + ']').addClass('isActive');
		}
	});


	$('.js_tabControle').on('click', function (e) {
		var target = $(event.target);
		var index = $('.tabs__item.isActive').data('tabitem');

		if (target.hasClass('controleTab__button_left')) {
			$('.tabs__item[data-tabitem=' + (index - 1) + ']').click();
		} else if (target.hasClass('controleTab__button_right')) {
			$('.tabs__item[data-tabitem=' + (index + 1) + ']').click();
		}
	});

	$('.js_tableLeft').on('click', function () {
		var table = $(this).parents('.controleTab').siblings('.tableOuther');
		var position = table.scrollLeft();

		table.scrollLeft(position - 50);
	});

	$('.js_tableRight').on('click', function () {
		var table = $(this).parents('.controleTab').siblings('.tableOuther');
		var position = table.scrollLeft();

		table.scrollLeft(position + 50);
	});

	$('.js_infographicsNext').on('click', function () {
		var container = $(this).parents('.infographicsContainer');
		var active = container.find('.infographicsContainer__imgContainer.isActive');

		if (active.next('.infographicsContainer__imgContainer').length > 0) {
			active.next('.infographicsContainer__imgContainer').addClass('isActive');
			active.removeClass('isActive');
		}
	});

	$('.js_infographicsPrev').on('click', function () {
		var container = $(this).parents('.infographicsContainer');
		var active = container.find('.infographicsContainer__imgContainer.isActive');

		if (active.prev('.infographicsContainer__imgContainer').length > 0) {
			active.prev('.infographicsContainer__imgContainer').addClass('isActive');
			active.removeClass('isActive');
		}
	});


	var fixHeightElements = function fixHeightElements(el) {
		var elementsToFixHeight = el.find('.lslide');
		var myArr = [];
		$.each($(elementsToFixHeight), function (index, item) {
			myArr.push($(item).innerHeight());
		});
		var maxHeight = Math.max.apply(null, myArr);
		el.css('min-height', maxHeight + 5 + 'px');
		elementsToFixHeight.css('height', maxHeight + 5 + 'px');
	};

	var customTogglers = function customTogglers(el) {
		var sliderOuter = el.parents('.slider__outer');
		var togglerLeft = sliderOuter.find('.slider__toggler_left');
		var togglerRight = sliderOuter.find('.slider__toggler_right');
		togglerLeft.click(function () {
			el.goToPrevSlide();
		});
		togglerRight.click(function () {
			el.goToNextSlide();
		});
	};

	$('.js_defaultSlider').lightSlider({
		item: 3,
		slideMargin: 30,
		speed: 700,
		pager: false,
		loop: false,
		enableDrag: false,
		controls: false,
		slideMove: 1,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		responsive: [{
			breakpoint: 1024,
			settings: {
				item: 2
			}
		}, {
			breakpoint: 768,
			settings: {
				item: 2
			}
		}, {
			breakpoint: 480,
			settings: {
				item: 1
			}
		}],
		onSliderLoad: function onSliderLoad(el) {
			customTogglers(el);

			var elementsToFixHeight = el.find('.lslide');
			var myArr = [];
			$.each($(elementsToFixHeight), function (index, item) {
				myArr.push($(item).innerHeight());
			});
			var maxHeight = Math.max.apply(null, myArr);
			el.css({ 'height': maxHeight + 5 + 'px' });
			el.find('.lslide').show();
		}
	});

	var ticking = false;
	var isFirefox = /Firefox/i.test(navigator.userAgent);
	var isIe = /MSIE/i.test(navigator.userAgent) || /Trident.*rv\:11\./i.test(navigator.userAgent);
	var scrollSensitivitySetting = 30; 
	var slideDurationSetting = 600; 
	var currentSlideNumber = 0;
	var totalSlideNumber = $(".background").length;

	function parallaxScroll(evt) {
		if ($('.teamInfo:hover').length == 0) {

			if (isFirefox) {
				var delta = evt.detail * -120;
			} else if (isIe) {
				var delta = -evt.deltaY;
			} else {
				var delta = evt.wheelDelta;
			}

			if (ticking != true) {
				if (delta <= -scrollSensitivitySetting) {
					ticking = true;
					if (currentSlideNumber !== totalSlideNumber - 1) {
						currentSlideNumber++;
						nextItem();
					}
					slideDurationTimeout(slideDurationSetting);
				}
				if (delta >= scrollSensitivitySetting) {
					ticking = true;
					if (currentSlideNumber !== 0) {
						currentSlideNumber--;
					}
					previousItem();
					slideDurationTimeout(slideDurationSetting);
				}
			}
		}
	}

	$('.menuList_desktop').on('click', '.menuList__link', function (e) {
		var element = $(this).attr('href');

		$(element).prevAll('.background').removeClass('up-scroll');
		$(element).prevAll('.background').addClass('down-scroll');

		$(element).nextAll('.background').removeClass('down-scroll');
		$(element).nextAll('.background').addClass('up-scroll');

		$(element).removeClass('down-scroll');
		$(element).addClass('up-scroll');

		currentSlideNumber = $(element).index();
		console.log(currentSlideNumber);
	});

	function slideDurationTimeout(slideDuration) {
		setTimeout(function () {
			ticking = false;
		}, slideDuration);
	}

	if ($(window).width() > 1365) {
		var mousewheelEvent = isFirefox ? "DOMMouseScroll" : "wheel";
		window.addEventListener(mousewheelEvent, _.throttle(parallaxScroll, 60), false);
	}

	function nextItem() {
		var $previousSlide = $(".background").eq(currentSlideNumber - 1);
		$previousSlide.removeClass("up-scroll").addClass("down-scroll");
	}

	function previousItem() {
		var $currentSlide = $(".background").eq(currentSlideNumber);
		$currentSlide.removeClass("down-scroll").addClass("up-scroll");
	}
});